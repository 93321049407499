@import "vue-select/dist/vue-select.css";

.v-select-custom {
  @apply max-w-full text-black;
}
.v-select-custom--themed {
  @apply text-theme;
}

.v-select-custom .vs__dropdown-toggle {
  @apply px-0 py-2 border-0 border-b rounded-none opacity-100 transition duration-300 bg-transparent;
  border-color: inherit;
}
.v-select-custom .vs__dropdown-toggle:hover {
  @apply opacity-60;
}
.v-select-custom--alt .vs__dropdown-toggle {
  @apply pt-1 text-center;
}
.v-select-custom--small .vs__dropdown-toggle {
  @apply text-sm px-2 py-1;
}
.v-select-custom--large .vs__dropdown-toggle {
  @apply text-lg py-1;
}

.v-select-custom .vs__selected-options {
  @apply p-0 min-w-0 flex-no-wrap;
}

.v-select-custom .vs__selected {
  @apply m-0 p-0 truncate min-w-0;
  color: inherit;
}
.v-select-custom.vs--open .vs__selected {
  @apply static opacity-100;
}

.v-select-custom .vs__search {
  @apply m-0 p-0;
}
.v-select-custom.vs--unsearchable .vs__search {
  @apply pointer-events-none;
}

.v-select-custom .vs__actions {
  @apply ml-2 p-0;
}

.v-select-custom .vs__open-indicator {
  background: currentColor;
}
.v-select-custom.vs--open .vs__open-indicator {
  @apply rotate-0;
}

.v-select-custom .vs__dropdown-menu {
  @apply min-w-0 mt-1 space-y-2 bg-white border-black rounded-2xl shadow-none text-base
  leading-normal text-center px-7 py-5 overflow-x-hidden top-full transform -translate-x-1/2;
  width: fit-content;
  max-width: 100vw;
  border-top-style: solid;
  left: 50%;
}
.v-select-custom--themed .vs__dropdown-menu {
  @apply bg-paint border-theme text-theme;
}
.v-select-custom--above .vs__dropdown-menu {
  @apply mt-0 mb-1 top-auto bottom-full;
}

.v-select-custom .vs__dropdown-option {
  @apply p-0 transition duration-300 truncate;
}
.v-select-custom .vs__dropdown-option--highlight {
  @apply bg-transparent opacity-60;
  color: inherit;
}
.v-select-custom .vs__dropdown-option--selected {
  @apply underline opacity-100;
}
