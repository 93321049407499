<template>
  <swiper class="swiper" ref="swiper" :options="swiperOptions">
    <swiper-slide v-for="(item, index) in items" :key="index">
      <div class="text-center space-y-3 pb-18">
        <img
          class="w-13 block mx-auto"
          :src="item.icon[0].url"
          :alt="item.icon[0].title"
        />
        <!-- eslint-disable vue/no-v-html -->
        <h4
          class="text-sm leading-snug text-black text-opacity-70"
          v-html="nl2br(item.text)"
        ></h4>
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import axios from "axios";

export default {
  name: "FlexibleContentRichFeatures",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    entry: {
      type: Number,
      default: null,
    },
    block: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      items: null,
      swiperOptions: {
        slidesPerView: 2,
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      axios({
        url: "/api",
        method: "post",
        data: {
          query: `
            query featuresQuery($entry: [QueryArgument], $block: [QueryArgument]) {
              products(id: $entry) {
                ... on paint_Product {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_features_BlockType {
                      features {
                        ... on features_BlockType {
                          icon {
                            url(width: 225, height: 225)
                            title
                          }
                          text
                        }
                      }
                    }
                  }
                }
                ... on supplementary_Product {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_features_BlockType {
                      features {
                        ... on features_BlockType {
                          icon {
                            url(width: 225, height: 225)
                            title
                          }
                          text
                        }
                      }
                    }
                  }
                }
                ... on coating_Product {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_features_BlockType {
                      features {
                        ... on features_BlockType {
                          icon {
                            url(width: 225, height: 225)
                            title
                          }
                          text
                        }
                      }
                    }
                  }
                }
              }
              entries(id: $entry) {
                ... on home_home_Entry {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_features_BlockType {
                      features {
                        ... on features_BlockType {
                          icon {
                            url(width: 225, height: 225)
                            title
                          }
                          text
                        }
                      }
                    }
                  }
                }
                ... on giftVouchers_giftVouchers_Entry {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_features_BlockType {
                      features {
                        ... on features_BlockType {
                          icon {
                            url(width: 225, height: 225)
                            title
                          }
                          text
                        }
                      }
                    }
                  }
                }
                ... on pages_richFlexibleContent_Entry {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_features_BlockType {
                      features {
                        ... on features_BlockType {
                          icon {
                            url(width: 225, height: 225)
                            title
                          }
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            entry: this.entry,
            block: this.block,
          },
        },
      }).then((result) => {
        /* eslint-disable no-unused-vars */
        /* eslint-disable prefer-destructuring */
        let data;
        const { products } = result.data.data;
        const { entries } = result.data.data;
        if (products.length) {
          data = products[0].flexibleContentRich[0];
        }
        if (entries.length) {
          data = entries[0].flexibleContentRich[0];
        }
        this.items = data.features;
      });
    },
  },
};
</script>

<style scoped>
.swiper >>> .swiper-pagination-bullets {
  @apply flex items-center justify-center space-x-2 bottom-0 pb-8;
}
.swiper >>> .swiper-pagination-bullet {
  @apply block mx-0 w-2 h-2 bg-cream opacity-100;
}
.swiper >>> .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-black;
}
</style>
