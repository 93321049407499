<template>
  <OverlayLayout>
    <div class="relative h-full w-full flex flex-col pb-ogs-large md:pb-10">
      <div class="flex-1 px-ogs-large mb-5 md:mb-10 relative z-20">
        <div class="h-full flex flex-col">
          <div class="flex-1 py-5 md:py-10">
            <div class="h-full relative">
              <template v-for="(palette, index) in palettes">
                <template v-for="(item, index) in palette.colours">
                  <img
                    v-show="item.id === displayColour.id"
                    class="absolute inset-0 w-full h-full object-contain rounded-full"
                    :src="item.image[0].url"
                  />
                </template>
              </template>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-x-gs">
            <div
              class="md:col-start-5 col-span-12 md:col-span-4 flex flex-col items-center justify-end"
            >
              <button class="btn-soft" @click="handleSelectColour">
                Shop {{ displayColour.title }}
              </button>
            </div>
            <div
              class="hidden md:flex col-span-2 md:col-span-4 items-end justify-end"
            >
              <button
                @click="triggerPrevSlide++"
                class="block w-8 h-8 focus:outline-none mr-2 transition duration-300"
                :class="{
                  'opacity-0': !showPrevSlideButton,
                  'opacity-100': showPrevSlideButton,
                }"
              >
                <svg
                  class="w-full h-full transition duration-300"
                  viewBox="0 0 30 30"
                  fill="var(--theme)"
                >
                  <path
                    d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,29A14,14,0,1,0,1,15,14,14,0,0,0,15,29ZM10.15,14.38l6.15,6.79L17,20.5l-5.52-6.1L17,8.68,16.31,8Z"
                  />
                </svg>
              </button>
              <button
                @click="triggerNextSlide++"
                @mouseover="showPrevSlideButton = false"
                @mouseout="showPrevSlideButton = true"
                v-tooltip="{
                  content: 'Discover more',
                  offset: 5,
                  placement: 'left',
                  classes: 'tooltip--simple-theme hidden! md:block!',
                  hideOnTargetClick: false,
                }"
                class="block w-8 h-8 focus:outline-none"
              >
                <svg
                  class="w-full h-full transition duration-300"
                  viewBox="0 0 30 30"
                  fill="var(--theme)"
                >
                  <path
                    d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm4.85,14.62L13.7,8.83,13,9.5l5.52,6.1L13,21.32l.72.69Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="relative flex-shrink-0 z-20">
        <ColourSelectorSlider
          :palettes="palettes"
          :selected-colour="displayColour"
          :is-mobile="isMobile"
          is-coating-product
          :trigger-next-slide="triggerNextSlide"
          :trigger-prev-slide="triggerPrevSlide"
          @change-colour="handleChangeColour"
          @select-colour="handleSelectColour"
        />
      </div>
    </div>
  </OverlayLayout>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import OverlayLayout from "../OverlayLayout.vue";
import ColourSelectorSlider from "../ColourSelector/ColourSelectorSlider.vue";

export default {
  name: "CoatingColourSelector",
  components: { OverlayLayout, ColourSelectorSlider },
  props: {
    palettes: {
      type: Array,
      required: true,
    },

    product: {
      type: Object,
      required: true,
    },

    selectedColour: {
      type: [Object, Boolean],
      required: true,
    },
  },
  data() {
    return {
      displayColour: this.selectedColour,
      isMobile,
      triggerNextSlide: 0,
      triggerPrevSlide: 0,
      showPrevSlideButton: true,
    };
  },
  methods: {
    handleChangeColour(colour) {
      this.displayColour = colour;
    },
    handleSelectColour() {
      this.$emit("select-colour", this.displayColour);
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-width: calc((theme("spacing.24") + (theme("spacing.2") * 2)) * 9);
}
</style>
