<template>
  <button
    class="colour-selector-paint-button"
    :class="[
      {
        'colour-selector-paint-button--black':
          selectedColour && colour.id === selectedColour.id,
      },
      isColourPage
        ? 'colour-selector-paint-button--colour-page'
        : 'h-10.5 w-10.5 rounded-full',
    ]"
    :style="{ backgroundColor: colour.hex }"
    @click="$emit('handle-click', colour)"
    @mouseover="$emit('change-colour', colour)"
  >
    <span class="sr-only">{{ colour.title }}</span>
  </button>
</template>

<script>
export default {
  name: "ColourSelectorPaintButton",
  props: {
    colour: {
      type: [Object, Boolean],
      required: true,
    },
    selectedColour: {
      type: [Object, Boolean],
      required: true,
    },
    isColourPage: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="css">
.colour-selector-paint-button {
  @apply relative block border border-transparent transition duration-300 focus:outline-none bg-grey;
}
.colour-selector-paint-button--black {
  @apply border-black;
}
.colour-selector-paint-button--colour-page {
  @apply rounded-xl md:rounded-2xl;
  width: 46.7px;
  height: 54px;
}
@screen md {
  .colour-selector-paint-button--colour-page {
    height: 62px;
  }
}
</style>
