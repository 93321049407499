<template>
  <div ref="hero" class="unblur-hero-wrapper">
    <div class="unblur-hero">
      <div class="absolute inset-0">
        <img
          class="w-full h-full object-cover hidden lg:block"
          :src="background"
        />
        <img
          class="w-full h-full object-cover lg:hidden"
          :src="backgroundMobile"
        />
      </div>
      <div class="unblur-hero__screen">
        <UnblurYourBackgroundLaptop
          class="unblur-hero__screen-desktop hidden lg:block"
        >
          <image
            :href="screen"
            x="0"
            y="0"
            width="100%"
            height="100%"
            preserve-aspect-ratio="xMidYMid slice"
          />
          <template v-slot:image>
            <image
              ref="imageDesktop"
              :href="blurredScreen"
              x="0"
              y="0"
              width="100%"
              height="100%"
              preserve-aspect-ratio="xMidYMid slice"
            />
          </template>
        </UnblurYourBackgroundLaptop>
        <UnblurYourBackgroundMobile class="lg:hidden">
          <image
            :href="screenMobile"
            x="0"
            y="0"
            width="100%"
            height="100%"
            preserve-aspect-ratio="xMidYMid slice"
          />
          <template v-slot:image-mobile>
            <image
              ref="imageMobile"
              :href="blurredScreenMobile"
              x="0"
              y="0"
              width="100%"
              height="100%"
              preserve-aspect-ratio="xMidYMid slice"
            />
          </template>
        </UnblurYourBackgroundMobile>

        <div class="absolute inset-0 text-center z-10">
          <div
            class="px-ogs flex flex-col justify-between items-center h-full py-20 lg:py-40"
          >
            <div class="flex-1 max-w-3xl mx-auto flex flex-col justify-center">
              <h1
                v-if="title"
                class="text-3xl lg:text-4xl xl:text-5xl mb-5 leading-tight"
                v-text="title"
              ></h1>
              <p
                v-if="text"
                class="text-md lg:text-lg w-54 px-1 lg:w-auto max-w-md mx-auto leading-snug"
                v-text="text"
              ></p>
            </div>
            <div>
              <button
                class="btn-pill w-56 min-h-10 text-sm md:text-base md:min-h-14"
                @click="handleClick"
              >
                Take the quiz
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div ref="target" class="bg-white lg:bg-transparent js-scroll-target">
      <div class="relative lg:z-20 px-ogs lg:-mt-24">
        <div class="lg:grid lg:grid-cols-12">
          <div class="lg:col-start-2 lg:col-span-10">
            <div
              class="w-full xl:max-w-6xl xl:px-5 lg:mx-auto lg:bg-white py-16 px-8 lg:px-16 rounded-xl text-center"
            >
              <h2
                v-if="heading"
                class="text-2xl lg:text-3xl xl:text-4xl mb-6 leading-tight"
                v-text="heading"
              >
                Unblur your background quiz
              </h2>
              <div
                v-if="richText"
                class="lg:text-lg xl:text-xl max-w-3xl px-6 mx-auto rte"
                v-html="richText"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import background from "../../../public/assets/images/unblur-background.jpg";
import screen from "../../../public/assets/images/unblur-screen.jpg";
import blurredScreen from "../../../public/assets/images/blur-screen.jpg";
import backgroundMobile from "../../../public/assets/images/unblur-background-mobile.jpg";
import screenMobile from "../../../public/assets/images/unblur-screen-mobile.jpg";
import blurredScreenMobile from "../../../public/assets/images/blur-screen-mobile.jpg";
import UnblurYourBackgroundLaptop from "./UnblurYourBackgroundLaptop.vue";
import UnblurYourBackgroundMobile from "./UnblurYourBackgroundMobile.vue";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "UnblurYourBackgroundHero",
  components: {
    UnblurYourBackgroundLaptop,
    UnblurYourBackgroundMobile,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    richText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      background,
      screen,
      backgroundMobile,
      screenMobile,
      blurredScreen,
      blurredScreenMobile,
    };
  },
  mounted() {
    const { hero, imageDesktop, imageMobile } = this.$refs;

    ScrollTrigger.matchMedia({
      "(min-width: 1024px)": () => {
        const heroAnimationDesktop = gsap.fromTo(
          imageDesktop,
          { autoAlpha: 1 },
          { duration: 0.75, ease: "power2.inOut", autoAlpha: 0 }
        );

        ScrollTrigger.create({
          trigger: hero,
          animation: heroAnimationDesktop,
          start: "top top",
          toggleActions: "play complete reverse reverse",
        });
      },
      "(max-width: 1024px)": () => {
        const heroAnimationMobile = gsap.fromTo(
          imageMobile,
          { autoAlpha: 1 },
          { duration: 0.75, ease: "power2.inOut", autoAlpha: 0 }
        );

        ScrollTrigger.create({
          trigger: hero,
          animation: heroAnimationMobile,
          start: "top top",
          toggleActions: "play complete reverse reverse",
        });
      },
    });
  },
  methods: {
    handleClick() {
      const target =
        this.$refs.target.getBoundingClientRect().top + window.pageYOffset - 12;

      window.scrollTo({
        top: target,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.unblur-hero-wrapper {
  @apply relative transition duration-300;
  background-color: var(--quiz-paint);
}

.unblur-hero {
  @apply z-10 -mt-shh relative overflow-hidden flex items-end max-h-screen min-w-full min-h-124;
  aspect-ratio: 4/7;
}

.unblur-hero__screen {
  @apply absolute inset-x-0 bottom-0 w-5/6 mx-auto -mb-4 max-h-hero max-w-sm lg:max-h-full;
}

.unblur-hero__screen-desktop {
  max-height: calc(100vh - 150px);
}

@screen lg {
  .unblur-hero {
    @apply h-auto w-full;
    aspect-ratio: 18/11;
    min-height: 700px;
  }

  .unblur-hero__screen {
    max-width: 1500px;
  }
}
</style>
