<template>
  <div class="v-select-menu relative">
    <div
      :required="required"
      :id="id"
      :data-testid="testId"
      class="v-select-menu__el relative block w-full bg-transparent appearance-none z-20 rounded-none"
      :class="{
        'text-theme': themed,
        'text-black': !themed,
        'font-semibold pl-2 pr-7 py-2': !small && !large,
        'text-sm pl-2 pr-7 py-1': small,
        'text-lg py-1': large,
      }"
    >
      <span>
        {{title}}
      </span>
    </div>
    <!-- <div
      :class="{
        'bg-theme': themed,
        'bg-black': !themed,
      }"
      class="v-select-menu__border w-full h-px"
    ></div> -->
  </div>
</template>

<script>
export default {
  name: "SelectMenu",
  props: {
    title: {
      type: String,
      default: "",
    },
    themed: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    testId: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style scoped>
.v-select-menu__el,
.v-select-menu__icon,
.v-select-menu__border {
  @apply opacity-100 transition duration-300;
}
.v-select-menu__el:focus {
  @apply outline-none;
}
.v-select-menu__el:focus + .v-select-menu__icon {
  @apply outline-none;
}
.v-select-menu__el:focus + .v-select-menu__icon + .v-select-menu__border {
  @apply outline-none;
}
.v-select-menu__el::-ms-expand {
  display: none;
}
.v-select-menu__el:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 DarkSlateBlue;
}
.v-select-menu__el:focus {
  outline: none;
}
.v-select-menu__el option {
  color: black !important;
}
</style>
