<template>
  <div
    v-if="content"
    class="bg-white text-black pb-8 pt-24 lg:pb-15 grid grid-cols-12 px-ogs gap-x-gs"
  >
    <transition name="fade" mode="out-in">
      <div v-if="!loading" class="col-span-12">
        <h3
          v-if="(colour && colour.text) || content.text"
          class="text-3xl md:text-4xl mb-20 md:mb-15 text-center max-w-xl md:max-w-3xl mx-auto leading-tight"
        >
          {{ (colour && colour.text) || content.text }}
        </h3>
      </div>
      <div v-else class="col-span-12 mb-20 md:mb-15">
        <div
          class="flex flex-col items-center space-y-3 max-w-xl md:max-w-3xl mx-auto"
        >
          <div class="h-10 bg-grey w-2/3 animate-pulse rounded"></div>
          <div class="h-10 bg-grey w-3/4 animate-pulse rounded"></div>
          <div class="h-10 bg-grey w-3/5 animate-pulse rounded"></div>
        </div>
      </div>
    </transition>
    <div
      v-if="colour && colour.imagesSecondary.length > 0 && !loading"
      class="md:hidden col-span-12 -mx-ogs"
    >
      <swiper ref="swiper" class="swiper" :options="swiperOptions">
        <!-- <swiper-slide
          class="swiper-slide swiper-slide--end mb-10"
        ></swiper-slide> -->
        <swiper-slide
          v-for="(image, index) in colour.imagesSecondary"
          :key="image.id"
          class="rounded-lg overflow-hidden mb-10"
          :class="marginClass(index)"
        >
          <picture>
            <source type="image/webp" :srcSet="image.webpSm" />
            <img
              :src="image.defaultSm"
              :alt="image.title"
              loading="lazy"
              width="750"
              height="1320"
            />
          </picture>
        </swiper-slide>
        <!-- <swiper-slide
          class="swiper-slide swiper-slide--end mb-10"
        ></swiper-slide> -->
        <div slot="pagination" class="swiper-pagination"></div>
      </swiper>
    </div>
    <div
      v-if="colour && colour.imagesSecondary.length > 0 && !loading"
      class="hidden md:flex gap-x-gs col-span-12 xl:col-span-10 xl:col-start-2"
    >
      <div
        v-for="image in colour.imagesSecondary.slice(0, 3)"
        :key="image.id"
        class="rounded-lg overflow-hidden flex-1"
      >
        <picture class="block">
          <source type="image/webp" :srcset="`${image.webpLg}`" />
          <img
            class="w-full"
            :srcset="`${image.defaultLg}`"
            :src="image.defaultLg"
            :alt="image.title"
            loading="lazy"
            width="950"
            height="1240"
          />
        </picture>
      </div>
    </div>
    <!-- <div class="mt-7 md:mt-15 text-center col-span-12">
      <button class="btn-pill">View More Inspo</button>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "FlexibleContentRichColourInspiration",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    entry: {
      type: Number,
      default: null,
    },
    block: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      colour: null,
      content: null,
      loading: false,
      mounted: false,
      swiperOptions: {
        slidesPerView: "auto",
        duration: 600,
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
  computed: {
    selectorIsOpen() {
      return this.$store.state.ui.selectorIsOpen;
    },
  },
  watch: {
    selectorIsOpen() {
      if (!this.selectorIsOpen) {
        this.getData();
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    marginClass(index) {
      if (index === 0) {
        return "swiper-slide--start";
      }
      if (index === this.colour.imagesSecondary.length - 1) {
        return "swiper-slide--end";
      }

      return "mx-2.75";
    },
    getData() {
      const urlParams = new URLSearchParams(window.location.search);
      const colourParam =
        urlParams.get("colour") != null
          ? urlParams.get("colour")
          : "egyptian-cotton";

      this.loading = true;
      axios({
        url: "/api",
        method: "post",
        data: {
          query: `
            query ColourInspirationQuery($colour: [String], $entry: [QueryArgument], $block: [QueryArgument]) {
              products(id: $entry) {
                ... on paint_Product {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_colourInspiration_BlockType {
                      text
                    }
                  }
                }
                ... on supplementary_Product {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_colourInspiration_BlockType {
                      text
                    }
                  }
                }
                ... on coating_Product {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_colourInspiration_BlockType {
                      text
                    }
                  }
                }
              }
              entries(id: $entry) {
                ... on home_home_Entry {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_colourInspiration_BlockType {
                      text
                    }
                  }
                }
                ... on giftVouchers_giftVouchers_Entry {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_colourInspiration_BlockType {
                      text
                    }
                  }
                }
                ... on pages_richFlexibleContent_Entry {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_colourInspiration_BlockType {
                      text
                    }
                  }
                }
              }
              categories(slug: $colour) {
                ... on paintColour_Category {
                  id
                  title
                  slug
                  text
                  colour
                  imagesSecondary {
                    id
                    webpLg: url(width: 950, height: 1240, format: "webp")
                    webpSm: url(width: 750, height: 1320, format: "webp")
                    defaultLg: url(width: 950, height: 1240)
                    defaultSm: url(width: 750, height: 1320)
                    title
                  }
                }
              }
            }
          `,
          variables: {
            entry: this.entry,
            block: this.block,
            colour: colourParam,
          },
        },
      }).then((result) => {
        /* eslint-disable prefer-destructuring */
        const { categories, products, entries } = result.data.data;

        if (categories.length > 0 && categories[0].title) {
          this.colour = categories[0];
        }
        if (products.length > 0) {
          this.content = products[0].flexibleContentRich[0];
        }
        if (entries.length > 0) {
          this.content = entries[0].flexibleContentRich[0];
        }

        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="css">
.swiper-slide {
  @apply w-4/5;
}

@screen sm {
  .swiper-slide {
    @apply w-2/5;
  }
}

.swiper-slide--start {
  @apply mr-2.75;
  margin-left: calc(
    theme("spacing.ogs-large") + 0.6875rem
  ); /*0.6875rem = spacing 2.75*/
}

.swiper-slide--end {
  @apply ml-2.75;
  margin-right: calc(
    theme("spacing.ogs-large") + 0.6875rem
  ); /*0.6875rem = spacing 2.75*/
}

.swiper >>> .swiper-pagination-bullets {
  @apply flex items-center justify-center space-x-2 bottom-0 md:pb-8;
}
.swiper >>> .swiper-pagination-bullet {
  @apply block mx-0 w-2 h-2 bg-cream opacity-100;
}
.swiper >>> .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-black;
}
</style>
