<template>
  <div class="border-b border-white border-opacity-25 pb-20 lg:pb-24">
    <div ref="carousel" class="press-carousel">
      <div
        v-for="(item, index) in articles"
        :key="index"
        class="w-full px-ogs text-center pt-20 lg:pt-24"
        :class="{ 'pb-8': articles.length > 1 }"
      >
        <PressItem
          :data="item"
          class="max-w-sm lg:max-w-xl mx-auto"
          featured
        ></PressItem>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from "flickity";
import PressItem from "./PressItem.vue";

export default {
  name: "PressFeaturedCarousel",
  components: {
    PressItem,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.carousel = new Flickity(this.$refs.carousel, {
      selectedAttraction: 0.015,
      prevNextButtons: false,
      pageDots: this.articles.length > 1,
    });
  },
};
</script>

<style>
.press-carousel .flickity-page-dots .dot {
  @apply mx-1 bg-white;
}

.press-carousel .flickity-slider {
  @apply flex items-center;
}
</style>
