<template>
  <swiper class="swiper" ref="swiper" :options="swiperOptions">
    <swiper-slide class="swiper-slide swiper-slide--end"></swiper-slide>
    <swiper-slide v-for="(item, index) in images" :key="index" class="mx-2.75">
      <picture>
        <source type="image/webp" :srcSet="item.image[0].webp" />
        <img
          :src="item.image[0].url"
          :alt="item.image[0].title"
          loading="lazy"
          width="960"
          height="1290"
        />
      </picture>
    </swiper-slide>
    <swiper-slide class="swiper-slide swiper-slide--end"></swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import axios from "axios";

export default {
  name: "FlexibleContentRichImageGallery",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    entry: {
      type: Number,
      default: null,
    },
    block: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      images: null,
      swiperOptions: {
        slidesPerView: "auto",
        freeMode: true,
      },
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      axios({
        url: "/api",
        method: "post",
        data: {
          query: `
            query galleryQuery($entry: [QueryArgument], $block: [QueryArgument]) {
              products(id: $entry) {
                ... on paint_Product {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_imageGallery_BlockType {
                      images {
                        ... on images_BlockType {
                          id
                          image {
                            id
                            url(height: 1290, width: 960)
                            webp: url(height: 1290, width: 960, format: "webp")
                            title
                          }
                        }
                      }
                    }
                  }
                }
                ... on supplementary_Product {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_imageGallery_BlockType {
                      images {
                        ... on images_BlockType {
                          id
                          image {
                            id
                            url(height: 1290, width: 960)
                            webp: url(height: 1290, width: 960, format: "webp")
                            title
                          }
                        }
                      }
                    }
                  }
                }
                ... on coating_Product {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_imageGallery_BlockType {
                      images {
                        ... on images_BlockType {
                          id
                          image {
                            id
                            url(height: 1290, width: 960)
                            webp: url(height: 1290, width: 960, format: "webp")
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
              entries(id: $entry) {
                ... on home_home_Entry {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_imageGallery_BlockType {
                      images {
                        ... on images_BlockType {
                          id
                          image {
                            id
                            url(height: 1290, width: 960)
                            webp: url(height: 1290, width: 960, format: "webp")
                            title
                          }
                        }
                      }
                    }
                  }
                }
                ... on giftVouchers_giftVouchers_Entry {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_imageGallery_BlockType {
                      images {
                        ... on images_BlockType {
                          id
                          image {
                            id
                            url(height: 1290, width: 960)
                            webp: url(height: 1290, width: 960, format: "webp")
                            title
                          }
                        }
                      }
                    }
                  }
                }
                ... on pages_richFlexibleContent_Entry {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_imageGallery_BlockType {
                      images {
                        ... on images_BlockType {
                          id
                          image {
                            id
                            url(height: 1290, width: 960)
                            webp: url(height: 1290, width: 960, format: "webp")
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            entry: this.entry,
            block: this.block,
          },
        },
      }).then((result) => {
        /* eslint-disable no-unused-vars */
        /* eslint-disable prefer-destructuring */
        let data;
        const { products } = result.data.data;
        const { entries } = result.data.data;
        if (products.length) {
          data = products[0].flexibleContentRich[0];
        }
        if (entries.length) {
          data = entries[0].flexibleContentRich[0];
        }
        this.images = data.images;
      });
    },
  },
};
</script>

<style scoped lang="css">
.swiper-slide {
  @apply w-4/5;
}

@screen sm {
  .swiper-slide {
    @apply w-2/5;
  }
}

.swiper-slide--end {
  @apply w-ogs;
  width: calc(
    theme("spacing.ogs-large") - 0.6875rem
  ); /*0.6875rem = spacing 2.75*/
}
</style>
