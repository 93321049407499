<template>
  <div
    class="grid md:grid-cols-2 border-t border-b border-theme md:divide-x divide-theme"
  >
    <div
      class="relative row-start-2 md:row-start-1 col-span-1 px-ogs py-10 border-t border-theme md:border-t-0 md:py-15 flex items-center justify-center"
    >
      <div class="space-y-4 lg:space-y-6 text-center">
        <h1 v-if="heading" class="text-theme text-3xl lg:text-4xl">
          {{ heading }}
        </h1>
        <p
          v-if="text"
          class="max-w-xs mx-auto text-theme"
          v-html="nl2br(text)"
        ></p>
        <form novalidate @submit.prevent="handleSubmit">
          <div
            class="flex flex-col lg:flex-row lg:items-center lg:justify-center px-4 lg:px-0 gap-6 text-left"
            :class="error && selectedItem.hasCustomAmount ? 'mb-6' : 'mb-10'"
          >
            <div class="flex items-center">
              <label
                class="flex-shrink-0 text-xxs text-theme font-semibold uppercase w-18 lg:w-auto lg:mr-3 mt-1"
                >Price</label
              >
              <SelectMenu
                class="flex-1"
                :value="selectedItem.id"
                :options="voucherOptions"
                :themed="true"
                large
                @input="changeSelectedItem($event)"
              />
            </div>
            <div v-if="selectedItem.hasCustomAmount" class="flex items-center">
              <label
                class="flex-shrink-0 text-xxs text-theme font-semibold uppercase w-18 lg:w-auto lg:mr-3 mt-1"
                >Amount*</label
              >
              <div class="custom-amount relative border-b border-theme w-full">
                <div
                  class="absolute inset-y-0 left-0 flex items-center z-10 text-theme"
                >
                  $
                </div>
                <input
                  v-model.number="$v.customAmount.$model"
                  type="number"
                  required="true"
                  class="relative block w-full text-lg text-theme py-1 lg:w-16 bg-transparent focus:outline-none appearance-none z-20 cursor-pointer ml-4"
                />
              </div>
            </div>
            <div class="flex items-center">
              <label
                class="flex-shrink-0 text-xxs text-theme font-semibold uppercase w-18 lg:w-auto lg:mr-3 mt-1"
                >qty</label
              >
              <SelectMenu
                v-model.number="qty"
                class="flex-1"
                :value="qty"
                :options="quantityOptions"
                :themed="true"
                large
              />
            </div>
          </div>
          <div
            v-if="error && $v.$invalid && selectedItem.hasCustomAmount"
            class="inline-block text-sm text-white text-center font-semibold bg-red mb-4 md:mb-7 p-1 px-2 rounded"
            role="alert"
          >
            {{ errorMessage }}
          </div>
          <div
            v-if="selectedItem.hasCustomAmount && !$v.customAmount.maxValue"
            class="inline-block text-sm text-white text-center font-semibold bg-red mb-4 md:mb-7 p-1 px-2 rounded"
            role="alert"
          >
            Please enter a value below $999,999
          </div>
          <div>
            <button
              class="btn-strong min-h-12 w-48 md:w-auto"
              :class="
                isSelected.textColour === 'white' ? 'btn-strong--alt' : ''
              "
            >
              <ButtonLoading :loading="false">{{ message }}</ButtonLoading>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row-start-1 col-span-1">
      <div class="relative py-10 md:pb-0 md:pt-10/12 md:h-full">
        <div
          v-if="selectedItem.image"
          class="md:absolute md:inset-0 px-8 md:px-0 md:w-7/10 md:mx-auto flex items-center"
        >
          <transition name="fade" mode="out-in">
            <div class="relative h-0 pb-4/7 w-full overflow-hidden">
              <img
                :key="selectedItem.image"
                :src="selectedItem.image"
                class="w-full h-auto md:h-full object-contain absolute inset-0"
              />
              <transition name="fade">
                <div
                  v-if="
                    selectedItem.hasCustomAmount &&
                    customAmount &&
                    $v.customAmount.maxValue
                  "
                  class="text-black absolute inset-0 z-20"
                >
                  <svg viewBox="0 0 500 286" class="w-full h-full">
                    <mask
                      id="a"
                      style="mask-type: alpha"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="500"
                      height="286"
                    >
                      <rect width="500" height="286" rx="30" fill="#E5B1C0" />
                    </mask>
                    <g mask="url(#a)">
                      <text fill="black" font-size="53.1429" text-anchor="end">
                        <tspan x="472" y="260.903">
                          ${{ customAmount.toLocaleString() }}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
              </transition>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  maxValue,
  minValue,
  numeric,
  required,
} from "vuelidate/lib/validators";
import { mapActions, mapMutations } from "vuex";
import SelectMenu from "../ui/SelectMenu.vue";
import ButtonLoading from "../ui/ButtonLoading.vue";

export default {
  name: "ProductVoucher",

  components: { SelectMenu, ButtonLoading },
  mixins: [validationMixin],

  props: {
    heading: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    vouchers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      qty: 1,
      customAmount: 50,
      selectedItem: this.vouchers[0],
      error: false,
    };
  },

  computed: {
    voucherOptions() {
      return this.vouchers.map(({ id, title }) => ({
        value: id,
        label: title,
      }));
    },

    quantityOptions() {
      return Array.from({ length: 10 }, (_, i) => i + 1).map((v) => ({
        value: v,
        label: v,
      }));
    },

    isSelected() {
      const salePrice = this.selectedItem.hasCustomAmount
        ? this.customAmount
        : this.selectedItem.price;
      return {
        ...this.selectedItem,
        salePrice,
        price: salePrice,
        isAvailable: true,
      };
    },

    message() {
      if (this.isSelected.salePrice) {
        const voucherTotal = this.isSelected.salePrice * this.qty;

        if (this.isSelected.hasCustomAmount) {
          if (this.$v.customAmount.maxValue) {
            return `Add To Cart - $${parseInt(
              voucherTotal,
              10
            ).toLocaleString()}`;
          }
        }

        if (!this.isSelected.hasCustomAmount) {
          return `Add To Cart - $${parseInt(
            voucherTotal,
            10
          ).toLocaleString()}`;
        }
      }
      return "Add To Cart";
    },

    errorMessage() {
      let message = "";

      if (this.isSelected.hasCustomAmount) {
        if (!this.$v.customAmount.required) {
          message = "Please enter a voucher amount";
        } else if (!this.$v.customAmount.minValue) {
          message = "Please enter a voucher amount over $50";
        } else {
          message = "Please enter a voucher amount with no decimal places";
        }
      }
      return message;
    },
  },

  watch: {
    selectedItem(value) {
      this.qty = 1;
      this.error = false;
      this.$store.commit("setSiteTheme", [value.hex, value.textColour]);
      this.$store.commit("setPaintTheme", [value.hex, value.textColour]);
    },
  },

  methods: {
    ...mapActions("cart", ["addItem"]),
    ...mapMutations(["openSiteCart"]),

    changeSelectedItem(itemId) {
      this.selectedItem = this.vouchers.find(({ id }) => id === itemId);
    },

    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = true;
      } else {
        this.error = false;
      }
      if (!this.error) {
        const options = this.isSelected.hasCustomAmount
          ? { amount: this.customAmount }
          : null;
        this.addItem({
          purchasable: this.isSelected,
          quantity: this.qty,
          options,
        });
        this.openSiteCart();
      }
    },
  },

  validations() {
    if (this.isSelected.hasCustomAmount) {
      return {
        customAmount: {
          required,
          numeric,
          minValue: minValue(50),
          maxValue: maxValue(999998),
        },
      };
    }
    return {};
  },
};
</script>

<style scoped lang="css">
/* Chrome, Safari, Edge, Opera */
.custom-amount input::-webkit-outer-spin-button,
.custom-amount input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.custom-amount input[type="number"] {
  -moz-appearance: textfield;
}
</style>
