<template>
  <div
    class="slider w-full flex md:block overflow-x-auto md:overflow-x-hidden overflow-y-hidden scrolling-touch"
  >
    <div
      v-for="(palette, paletteIndex) in palettes"
      :key="paletteIndex"
      class="slide w-auto"
      :class="`palette-${palette.id}`"
    >
      <div class="slide__inner">
        <div class="bg-smoke rounded-xl pt-4 md:pt-6 pb-4 md:pb-5 px-3 md:px-4">
          <div class="flex justify-between items-end px-2 mb-3 md:mb-4">
            <div class="mr-5">
              <h4
                v-if="!isCoatingProduct"
                class="text-xxs font-semibold uppercase tracking-widest"
              >
                Palette {{ paletteIndex + 1 }}/{{ palettes.length }}
              </h4>
              <h3>{{ palette.title }}</h3>
            </div>
            <ProductSwatchBook
              v-if="swatchBook"
              :product="swatchBook"
              :title="palette.title"
            />
          </div>
          <div
            class="flex items-center h-13 rounded-full bg-white space-x-3 px-1.25"
          >
            <div v-for="colour in palette.colours" :key="colour.id">
              <ColourSelectorPaintButton
                v-if="isColourPage || isPaintProduct"
                :colour="colour"
                :selected-colour="selectedColour"
                @handle-click="handleClick"
                @change-colour="$emit('change-colour', colour)"
              />
              <ColourSelectorCoatingButton
                v-if="isCoatingProduct"
                :colour="colour"
                :selected-colour="selectedColour"
                @change-colour="$emit('change-colour', colour)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from "flickity";
import ColourSelectorPaintButton from "./ColourSelectorPaintButton.vue";
import ColourSelectorCoatingButton from "./ColourSelectorCoatingButton.vue";
import ProductSwatchBook from "../Product/ProductSwatchBook.vue";

export default {
  name: "ColourSelectorSlider",

  components: {
    ColourSelectorPaintButton,
    ColourSelectorCoatingButton,
    ProductSwatchBook,
  },

  props: {
    palettes: {
      type: Array,
      required: true,
    },

    selectedColour: {
      type: [Object, Boolean],
      required: true,
    },

    swatchBook: {
      type: [Object, Boolean],
      required: false,
    },

    isMobile: {
      type: Boolean,
      default: false,
    },

    isColourPage: {
      type: Boolean,
      default: false,
    },

    isPaintProduct: {
      type: Boolean,
      default: false,
    },

    isCoatingProduct: {
      type: Boolean,
      default: false,
    },
    triggerNextSlide: {
      type: Number,
      default: 0,
    },
    triggerPrevSlide: {
      type: Number,
      default: 0,
    },
    scrollTo: {
      type: Number,
      default: 0,
    }
  },

  watch: {
    triggerNextSlide() {
      this.$flickity.next(true);
    },
    triggerPrevSlide() {
      this.$flickity.previous(true);
    },
    scrollTo() {
      this.$flickity.selectCell(`.palette-${this.scrollTo}`);
    }
  },

  mounted() {
    this.$flickity = new Flickity(this.$el, {
      freeScroll: true,
      contain: true,
      // cellAlign: "left",
      prevNextButtons: false,
      pageDots: false,
      watchCSS: true,
    });
  },

  destroyed() {
    // destroy after transition finishes 300ms
    setTimeout(() => {
      this.$flickity.destroy();
      this.$flickity = null;
    }, 500);
  },

  methods: {
    handleClick(colour) {
      if (this.isMobile || this.isColourPage) return;
      this.$emit("select-colour", colour);
    },
  },
};
</script>

<style scoped lang="css">
.slider:after {
  content: "";
}

@screen md {
  .slider:after {
    content: "flickity";
    display: none;
  }
}

.slide .slide__inner {
  @apply px-2.75;
}

.slide:first-child .slide__inner {
  @apply pl-ogs-large;
}

.slide:last-child .slide__inner {
  @apply pr-ogs-large;
}
</style>
