<template>
  <div class="bg-paint text-theme lg:pt-24 pb-22 lg:pb-44">
    <div class="relative">
      <div class="lg:px-ogs-large z-10">
        <div class="hidden lg:grid grid-cols-12 gap-x-gs">
          <div
            class="col-span-4 xl:col-span-6 pt-16 flex flex-col justify-between"
          >
            <div class="flex items-center font-bold text-sm">
              <span class="w-3 text-right">{{ carouselIndex }}</span>
              <span class="opacity-75">/</span>
              <span class="w-3 text-left">{{ data.length }}</span>
            </div>
            <Pagination @next="carousel.next()" @prev="carousel.previous()" />
          </div>
          <div class="col-span-8 xl:col-span-6">
            <div class="relative h-0 pt-8/12 overflow-hidden">
              <div
                v-for="(item, index) in data"
                :key="index"
                class="absolute inset-0"
              >
                <transition name="slow-fade">
                  <picture v-if="index === carouselRealIndex">
                    <source type="image/webp" :srcSet="item.image[0].webp" />
                    <img
                      class="absolute top-0 left-0 w-full h-full object-cover"
                      :src="item.image[0].url"
                      :alt="item.image[0].title"
                      loading="lazy"
                      width="1024"
                      :height="1024 * (8 / 12)"
                    />
                  </picture>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <div
          ref="carousel"
          class="lg:absolute lg:top-0 lg:inset-x-0 z-20 pb-8 lg:pt-0"
        >
          <div
            v-for="(item, index) in data"
            :key="index"
            class="carousel-item relative w-full lg:pt-22"
          >
            <div
              class="relative py-8 mb-8 lg:py-0 lg:mb-0 min-h-54 sm:min-h-86 flex items-center lg:min-h-0 lg:block"
            >
              <div class="absolute w-3/5 h-full top-0 right-0 z-10 lg:hidden">
                <picture>
                  <source type="image/webp" :srcSet="item.image[0].webp" />
                  <img
                    class="absolute top-0 left-0 w-full h-full object-cover"
                    :src="item.image[0].url"
                    :alt="item.image[0].title"
                    loading="lazy"
                    width="1024"
                    :height="1024 * (3 / 5)"
                  />
                </picture>
              </div>
              <div class="w-full relative px-ogs-large z-20">
                <div class="flex lg:hidden items-center font-bold text-sm mb-2">
                  <span class="w-3 text-right">{{ index + 1 }}</span>
                  <span class="opacity-75">/</span>
                  <span class="w-3 text-left">{{ data.length }}</span>
                </div>
                <h2
                  class="w-2/3 md:w-1/2 lg:w-full text-2xl sm:text-4xl lg:text-6xl xl:text-7xl leading-tight"
                >
                  {{ item.heading }}
                </h2>
              </div>
            </div>
            <div v-if="item.body" class="lg:max-w-xl px-ogs-large lg:pt-4">
              <p class="text-sm">{{ item.body }}</p>
            </div>
          </div>
        </div>
        <div class="px-ogs-large lg:hidden">
          <Pagination @next="carousel.next()" @prev="carousel.previous()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from "flickity";
import Pagination from "../ui/Pagination.vue";

export default {
  name: "FlexibleContentRichCarousel",
  components: { Pagination },
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      carouselIndex: 1,
      carouselRealIndex: 0,
      carousel: null,
    };
  },
  mounted() {
    this.carousel = new Flickity(this.$refs.carousel, {
      selectedAttraction: 0.015,
      prevNextButtons: false,
      pageDots: false,
    });
    this.carousel.on("change", (index) => {
      this.carouselIndex = index + 1;
      this.carouselRealIndex = index;
    });
  },
};
</script>

<style scoped>
.carousel-item {
  opacity: 0;
  transition: opacity 2500ms;
}
.carousel-item.is-selected {
  opacity: 1;
}
</style>
