<template>
    <div class="loader-skeleton"></div>
</template>

<script>
export default {
    name: "LoaderSkeleton",
};
</script>

<style scoped>
.loader-skeleton {
    @apply w-full h-full relative overflow-hidden rounded-md;
}
.loader-skeleton:before {
    @apply absolute inset-0 bg-white opacity-25;
    content: "";
}
.loader-skeleton:after {
    @apply absolute inset-0;
    content: "";
    transform: translate3d(-100%, 0, 0);
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0)
    );
    animation: loaderSkeleton 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
}
@keyframes loaderSkeleton {
    100% {
        transform: translate3d(100%, 0, 0);
    }
}
</style>
