<template>
  <div>
    <div
      v-if="sheens.length"
      class="flex items-center"
      :class="
        selectorStyle === 'alternative' ? 'alternative-selector-wrapper' : ''
      "
    >
      <div class="hidden lg:block mr-4">
        <label
          class="text-xxs text-theme font-semibold uppercase mt-1"
          for="paint-sheen-select"
          >Sheen</label
        >
        <ButtonInfo
          v-if="(infoHeading || infoText) && sheens.length > 1"
          class="ml-2px"
          themed
          :heading="infoHeading"
          :body="infoText"
        />
      </div>
      <SelectMenu
        v-if="sheens.length > 1"
        id="paint-sheen-select"
        :value="selectedSheen.id"
        class="hidden lg:block"
        large
        themed
        :options="sheenOptions"
        :open-above="openAbove"
        @input="changeSheen($event)"
      >
      </SelectMenu>
      <SelectMenuSingle
        v-else
        id="paint-sheen-select"
        class="hidden lg:block"
        :title="sheens[0].title"
        large
        themed
      />
      <SelectMenuWithLabel
        v-if="sheens.length > 1"
        id="paint-sheen-select"
        :value="selectedSheen.id"
        :options="sheenOptions"
        class="lg:hidden"
        label="Sheen"
        themed
        :info-heading="infoHeading"
        :info-text="infoText"
        :select-style="selectorStyle"
        :open-above="openAbove"
        @input="changeSheen($event)"
      />
      <SelectMenuWithLabelSingle
        v-else
        id="paint-sheen-select-mobile"
        class="lg:hidden"
        :select-style="selectorStyle"
        label="Sheen"
        :title="sheens[0].title"
        large
        themed
      />
    </div>
    <div
      v-if="sizes.length"
      class="flex items-center"
      :class="
        selectorStyle === 'alternative' ? 'alternative-selector-wrapper' : ''
      "
    >
      <label
        class="hidden lg:block text-xxs text-theme font-semibold uppercase mr-4 mt-1"
        for="paint-size-select"
        >Size</label
      >
      <SelectMenu
        id="paint-size-select"
        :required="true"
        :value="selectedSize.id"
        :options="sizeOptions"
        class="hidden lg:block"
        large
        themed
        :open-above="openAbove"
        @input="changeSize($event)"
      />
      <SelectMenuWithLabel
        id="paint-size-select-mobile"
        :required="true"
        :value="selectedSize.id"
        :options="sizeOptions"
        class="lg:hidden"
        :select-style="selectorStyle"
        label="Size"
        themed
        :open-above="openAbove"
        @input="changeSize($event)"
      />
    </div>
  </div>
</template>

<script>
import SelectMenu from "../ui/SelectMenu.vue";
import SelectMenuSingle from "../ui/SelectMenuSingle.vue";
import SelectMenuWithLabel from "../ui/SelectMenuWithLabel.vue";
import SelectMenuWithLabelSingle from "../ui/SelectMenuWithLabelSingle.vue";
import ButtonInfo from "../ui/ButtonInfo.vue";

export default {
  name: "ProductVariantSelector",

  components: {
    SelectMenu,
    SelectMenuSingle,
    SelectMenuWithLabel,
    SelectMenuWithLabelSingle,
    ButtonInfo,
  },

  props: {
    sheens: {
      type: Array,
      required: true,
    },
    sizes: {
      type: Array,
      required: true,
    },
    selectedSheen: {
      type: [Object, Boolean],
      required: true,
    },
    selectedSize: {
      type: [Object, Boolean],
      required: true,
    },
    selectorStyle: {
      type: String,
      default: "default",
    },
    openAbove: {
      type: Boolean,
      default: false,
    },
    infoHeading: {
      type: String,
      default: null,
    },
    infoText: {
      type: String,
      default: null,
    },
  },

  computed: {
    sheenOptions() {
      return this.sheens.map(({ id, title }) => ({ value: id, label: title }));
    },
    sizeOptions() {
      return this.sizes.map(({ id, title }) => ({ value: id, label: title }));
    },
  },

  methods: {
    changeSheen(sheenId) {
      this.$emit(
        "change-sheen",
        this.sheens.find((sheen) => sheen.id === sheenId)
      );
    },

    changeSize(sizeId) {
      this.$emit(
        "change-size",
        this.sizes.find((size) => size.id === sizeId)
      );
    },
  },
};
</script>

<style>
.alternative-selector-wrapper {
  @apply flex-1 text-center lg:text-left items-center justify-center py-5 px-3 sm:px-5 lg:py-3;
}
</style>
