<!-- font-semibold  -->
<template>
  <div
    class="v-select-menu-with-label relative"
    :class="selectStyle === 'alternative' ? 'w-auto' : 'w-full'"
  >
    <div
      class="flex items-center"
      :class="{ 'flex-col': selectStyle === 'alternative' }"
    >
      <div
        :class="[
          infoHeading || infoText ? '' : 'w-14',
          {
            'flex justify-center items-center': selectStyle === 'alternative',
          },
        ]"
      >
        <label
          :class="{
            'text-theme': themed,
            'text-black': !themed,
          }"
          class="text-xxs font-semibold uppercase truncate"
          >{{ label }}</label
        >
        <ButtonInfo
          v-if="infoHeading || infoText"
          class="ml-2"
          themed
          :heading="infoHeading"
          :body="infoText"
        />
      </div>
      <div
        :id="id"
        required="required"
        :data-testid="testId"
        :class="{
          'text-theme': themed,
          'text-black': !themed,
          'px-5': selectStyle !== 'alternative',
        }"
        class="v-select-menu-with-label__el relative block w-full bg-transparent appearance-none rounded-none z-20 text-lg py-2"
      >
        <span>
          {{ title }}
        </span>
      </div>
    </div>
    <!-- <div
      :class="{
        'bg-theme': themed,
        'bg-black': !themed,
      }"
      class="v-select-menu-with-label__border w-full h-px"
    ></div> -->
  </div>
</template>

<script>
import ButtonInfo from "./ButtonInfo.vue";

export default {
  name: "SelectMenuWithLabel",
  components: {
    ButtonInfo,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    testId: {
      type: String,
      default: null,
    },
    themed: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    selectStyle: {
      type: String,
      default: "default",
    },
    infoHeading: {
      type: String,
      default: null,
    },
    infoText: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.v-select-menu-with-label__el,
.v-select-menu-with-label__icon,
.v-select-menu-with-label__border {
  @apply opacity-100 transition duration-300;
}
.v-select-menu-with-label__el:focus {
  @apply outline-none opacity-40;
}
.v-select-menu-with-label__el:focus + .v-select-menu-with-label__icon {
  @apply outline-none opacity-40;
}
.v-select-menu-with-label__el:focus
  + .v-select-menu-with-label__icon
  + .v-select-menu-with-label__border {
  @apply outline-none opacity-40;
}
</style>
