<template>
  <div class="fixed inset-0 z-60">
    <SiteMenuFlyoutLayout :heading="heading" @close="$emit('close')">
      <div class="flex flex-col gap-y-18 py-9 text-lg text-theme">
        <div
          v-for="(item, itemKey) in items"
          :key="itemKey"
          class="min-w-fit w-1/2 relative z-10 text-center"
          :class="{
            'ml-auto': itemKey % 2 === 0,
            'mr-auto': itemKey % 2 !== 0,
          }"
        >
          <a v-if="item.link.url" class="block" :href="item.link.url">
            <span class="whitespace-pre-line">{{ item.heading }}</span>
          </a>
          <button v-else class="focus:outline-none" @click="openMenu(itemKey)">
            <span class="whitespace-pre-line">{{ item.heading }}</span>
          </button>

          <InlineSvg
            class="item-bg-image m-auto absolute inset-0 -z-10"
            :src="item.backgroundImage[0].url"
            :width="item.backgroundImage[0].width"
            :height="item.backgroundImage[0].height"
            :transform-source="removeSvgFill"
            :fill="backgroundImageColour"
          />
        </div>
      </div>
    </SiteMenuFlyoutLayout>

    <transition v-for="(item, itemKey) in items" :key="itemKey" name="fade">
      <SiteMenuFlyoutMegaSecondarySub
        v-if="isOpenedMenu(itemKey)"
        :heading="item.heading"
        :background-image="item.backgroundImage[0]"
        :background-image-colour="backgroundImageColour"
        :items="item.items"
        @close="closeMenu"
      />
    </transition>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import SiteMenuFlyoutMixin from "./SiteMenuFlyoutMixin.vue";
import SiteMenuFlyoutMegaSecondarySub from "./SiteMenuFlyoutMegaSecondarySub.vue";
import { removeSvgFill } from "../../js/utils";

export default {
  name: "SiteMenuFlyoutMegaSecondary",

  components: {
    InlineSvg,
    SiteMenuFlyoutMegaSecondarySub,
  },

  mixins: [SiteMenuFlyoutMixin],

  props: {
    backgroundImageColour: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  methods: {
    removeSvgFill,
  },
};
</script>

<style scoped>
.item-bg-image {
  max-width: 170px;
  max-height: 150px;
}
</style>
