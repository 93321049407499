<template>
  <div>
    <div :style="cssProps" class="grid lg:grid-cols-2">
      <a
        v-for="(item, index) in articles.slice(0, limit)"
        :key="index"
        :href="item.externalLink"
        target="_blank"
        class="press-item transition py-20 lg:py-24 px-ogs text-center border-b border-white border-opacity-25 transition-background duration-300 flex items-center"
        :class="{ 'border-r': index % 2 == 0 }"
        :aria-label="item.title"
      >
        <PressItem
          :data="item"
          class="max-w-sm lg:max-w-md mx-auto"
        ></PressItem>
      </a>
    </div>
    <div
      v-if="limit < articles.length"
      class="mt-12 lg:mt-20 pb-15 lg:pb-24 flex justify-center"
    >
      <a class="btn-soft btn-soft--alt" @click="limit += 4">Load More</a>
    </div>
  </div>
</template>

<script>
import PressItem from "./PressItem.vue";

export default {
  name: "PressGrid",
  components: {
    PressItem,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
    hoverColour: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      limit: 4,
    };
  },
  computed: {
    cssProps() {
      return {
        "--bg-hover-colour": this.hoverColour,
      };
    },
  },
};
</script>

<style scoped>
.press-item:hover {
  background-color: var(--bg-hover-colour);
}
</style>
