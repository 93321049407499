<template>
  <div>
    <div v-if="heading || button.url" class="px-ogs">
      <div class="py-6 lg:py-10 flex justify-between items-center">
        <div v-if="heading" class="text-md lg:text-xl">{{ heading }}</div>
        <a
          v-if="button.url"
          :href="button.url"
          class="link flex items-center space-x-2"
          :target="button.target"
        >
          <span class="underline">{{ button.customText || "View all" }}</span>
          <div class="w-5">
            <IconArrowRight />
          </div>
        </a>
      </div>
    </div>
    <div class="slide-wrapper w-full relative">
      <div
        v-for="(item, index) in items"
        :key="index"
        :style="{
          backgroundColor: item.colour[0].colour
            ? item.colour[0].colour
            : '#f0f0f0',
        }"
        class="slide lg:absolute lg:left-0 lg:top-0 lg:h-124"
        :class="[
          `text-${item.colour[0].textColour}`,
          `slide-${index + 1}`,
          index <= activeIndex ? 'slide--has-moved' : '',
          windowIsResizing
            ? 'transition-none'
            : 'transition-transform duration-1000',
        ]"
      >
        <div
          class="text-md lg:text-xl px-ogs pb-ogs lg:py-10 lg:px-8 flex flex-col h-full justify-between"
          :class="{
            'opacity-50 lg:opacity-30 cursor-pointer hover:opacity-70 transition-opacity duration-300':
              index !== activeIndex,
          }"
          @click="handleClick(index)"
        >
          <div
            class="flex justify-between items-center lg:items-baseline h-18 lg:h-auto"
          >
            <div
              class="flex items-center lg:items-start space-x-3 sm:space-x-5 mr-4"
            >
              <div>{{ index + 1 }}</div>
              <transition name="fade">
                <div v-show="index == activeIndex" class="hidden lg:block">
                  <a
                    class="link"
                    :href="`/colours?colour=${item.colour[0].slug}`"
                    >{{ item.colour[0].title }}</a
                  >
                </div>
              </transition>
              <div class="lg:hidden leading-tight">
                <a :href="`/colours?colour=${item.colour[0].slug}`">{{
                  item.colour[0].title
                }}</a>
              </div>
            </div>
            <transition name="fade">
              <button
                v-show="index == activeIndex && index + 1 !== items.length"
                class="w-10 link hidden lg:block focus:opacity-60"
                @click.stop="handleClick(index + 1)"
              >
                <IconArrowRightAlt />
                <span class="sr-only">Next Colour</span>
              </button>
            </transition>
            <div class="lg:hidden focus:outline-none">
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  v-show="index !== activeIndex"
                  d="M9.295 18.5899V-7.7453e-05"
                  stroke="currentColor"
                />
                <path d="M18.59 9.29492H0" stroke="currentColor" />
              </svg>
            </div>
          </div>
          <transition name="fade">
            <div v-show="index == activeIndex">
              <div class="flex space-x-5">
                <ProductColourTestPot
                  v-if="testPotProductId"
                  :loading="isLoading"
                  :test-pot="getTestPot(item.colour[0].id)"
                  source="popular_colours_block"
                  :paint-colour="item.colour[0].colour || ''"
                  :text-colour="item.colour[0].textColour || ''"
                  :colour-title="item.colour[0].title || ''"
                >
                  {{
                    item.cartButtonText || `${item.colour[0].title} Test Pot`
                  }}
                  - ${{
                    getTestPot(item.colour[0].id)
                      ? getTestPot(item.colour[0].id).salePrice
                      : 6
                  }}
                </ProductColourTestPot>
                <a
                  v-if="item.button.url"
                  :href="item.button.url"
                  class="btn-soft"
                  :class="{
                    'btn-soft--alt': item.colour[0].textColour === 'white',
                  }"
                  :target="item.button.target"
                >
                  {{ item.button.customText }}
                </a>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import api from "../../js/apiClient";
import ProductColourTestPot from "../Product/TestPotAddToCart.vue";
import IconArrowRightAlt from "../../../templates/_includes/svg/icon-arrow-right-alt.svg";
import IconArrowRight from "../../../templates/_includes/svg/icon-arrow-right.svg";

export default {
  name: "FlexibleContentRichPopularColours",
  components: {
    ProductColourTestPot,
    IconArrowRightAlt,
    IconArrowRight,
  },
  props: {
    testPotProductId: {
      type: Number,
      default: null,
    },
    entry: {
      type: Number,
      default: null,
    },
    block: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      heading: null,
      button: {},
      isLoading: false,
      popularColoursIds: [],
      popularTestPots: [],
      activeIndex: 0,
      items: null,
      windowWidth: 0,
      windowIsResizing: false,
      slideTimer: null,
    };
  },
  beforeMount() {
    this.getData();
  },
  mounted() {
    window.addEventListener("resize", this.handleResizeEvent);
    this.windowWidth = window.innerWidth;

    this.slideTimer = setInterval(() => {
      const nextIndex = this.activeIndex + 1;
      if (nextIndex > this.items.length - 1) {
        clearInterval(this.slideTimer);
        return;
      }

      this.changeSlide(nextIndex);
    }, 3000);
  },
  beforeDestroy() {
    clearInterval(this.slideTimer);
  },
  methods: {
    getTestPot(colourId) {
      const testPot = this.popularTestPots.find(
        (popularTestPot) => popularTestPot.colourId === colourId
      );
      return testPot;
    },
    handleResizeEvent() {
      let resizeTimer = "";

      this.windowIsResizing = true;

      clearTimeout(resizeTimer);

      resizeTimer = setTimeout(() => {
        this.windowIsResizing = false;
      }, 400);

      this.windowWidth = window.innerWidth;
    },
    handleClick(index) {
      clearInterval(this.slideTimer);
      this.changeSlide(index);
    },
    changeSlide(index) {
      if (this.windowWidth < 1024 && index === this.activeIndex) {
        if (index !== 3) {
          this.activeIndex = index + 1;
        }
      } else {
        this.activeIndex = index;
      }
    },
    async getData() {
      this.isLoading = true;

      await axios({
        url: "/api",
        method: "post",
        data: {
          query: `
            fragment popularColoursData on flexibleContentRich_popularColours_BlockType {
              ... on flexibleContentRich_popularColours_BlockType {
                heading
                button {
                  customText
                  url
                  target
                }
                popularColoursItems {
                  ... on popularColoursItems_BlockType {
                    colour {
                      ... on paintColour_Category {
                        id
                        slug
                        colour
                        title
                        textColour
                      }
                    }
                    cartButtonText
                    button {
                      customText
                      url
                      target
                    }
                  }
                }
              }
            }

            query popularColoursQuery($entry: [QueryArgument], $block: [QueryArgument]) {
              products(id: $entry) {
                ... on paint_Product {
                  flexibleContentRich(id: $block) {
                    ...popularColoursData
                  }
                }
                ... on supplementary_Product {
                  flexibleContentRich(id: $block) {
                    ...popularColoursData
                  }
                }
                ... on coating_Product {
                  flexibleContentRich(id: $block) {
                    ...popularColoursData
                  }
                }
              }
              entries(id: $entry) {
                ... on home_home_Entry {
                  flexibleContentRich(id: $block) {
                    ...popularColoursData
                  }
                }
                ... on giftVouchers_giftVouchers_Entry {
                  flexibleContentRich(id: $block) {
                    ...popularColoursData
                  }
                }
                ... on pages_richFlexibleContent_Entry {
                  flexibleContentRich(id: $block) {
                    ...popularColoursData
                  }
                }
              }
            }
          `,
          variables: {
            entry: this.entry,
            block: this.block,
          },
        },
      }).then((result) => {
        /* eslint-disable no-unused-vars */
        /* eslint-disable prefer-destructuring */
        let data;
        const { products } = result.data.data;
        const { entries } = result.data.data;
        if (products.length) {
          data = products[0].flexibleContentRich[0];
        }
        if (entries.length) {
          data = entries[0].flexibleContentRich[0];
        }
        this.heading = data.heading;
        this.button = data.button;
        this.items = data.popularColoursItems;
        this.popularColoursIds = this.items.map((item) => item.colour[0].id);
      });

      try {
        this.isLoading = true;

        const paintTestPots = await api.variants.queryPaintTestPots(
          this.testPotProductId,
          this.popularColoursIds
        );

        this.popularTestPots = this.getColourIds(paintTestPots?.variants);

        this.isLoading = false;
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
        this.popularTestPots = [];
        this.isLoading = false;
      }
    },

    getColourIds(paintVariants) {
      const paintVariantsFmt = paintVariants.map((variant) => {
        return { ...variant, colourId: variant.paintColour[0].id };
      });
      return paintVariantsFmt;
    },
  },
  beforeDestory() {
    window.removeEventListener("resize", this.handleResizeEvent);
  },
};
</script>

<style scoped lang="css">
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1000ms;
}

.slide-wrapper {
  height: calc(theme("spacing.124") + (theme("spacing.18") * 3));
  overflow: hidden;
}

.slide {
  width: 100%;
  height: theme("spacing.124");
}

.slide-1 {
  transform: translate3d(0, 0, 0);
}

.slide-1.slide--has-moved {
  transform: translate3d(0, 0, 0);
}

.slide-2.slide--has-moved {
  transform: translate3d(
    0,
    calc((theme("spacing.124") * -1) + theme("spacing.18")),
    0
  );
}

.slide-3 {
  transform: translate3d(
    0,
    calc((theme("spacing.124") * -1) + theme("spacing.18")),
    0
  );
}

.slide-3.slide--has-moved {
  transform: translate3d(
    0,
    calc((theme("spacing.124") * -2) + (theme("spacing.18") * 2)),
    0
  );
}

.slide-4 {
  transform: translate3d(
    0,
    calc((theme("spacing.124") * -2) + (theme("spacing.18") * 2)),
    0
  );
}

.slide-4.slide--has-moved {
  transform: translate3d(
    0,
    calc((theme("spacing.124") * -3) + (theme("spacing.18") * 3)),
    0
  );
}

@media (min-width: 1024px) {
  .slide {
    width: calc(100vw - (theme("spacing.20") * 3));
  }

  .slide-wrapper {
    @apply h-124;
  }

  .slide-1 {
    transform: translate3d(0, 0, 0);
  }

  .slide-1.slide--has-moved {
    transform: translate3d(0, 0, 0);
  }

  .slide-2 {
    transform: translate3d(calc(100vw - (theme("spacing.20") * 3)), 0, 0);
  }

  .slide-2.slide--has-moved {
    transform: translate3d(calc((theme("spacing.20") * 1)), 0, 0);
  }

  .slide-3 {
    transform: translate3d(calc(100vw - (theme("spacing.20") * 2)), 0, 0);
  }

  .slide-3.slide--has-moved {
    transform: translate3d(calc((theme("spacing.20") * 2)), 0, 0);
  }

  .slide-4 {
    transform: translate3d(calc(100vw - (theme("spacing.20") * 1)), 0, 0);
  }

  .slide-4.slide--has-moved {
    transform: translate3d(calc((theme("spacing.20") * 3)), 0, 0);
  }
}
</style>
