<template>
  <div class="fixed right-0 inset-y-0 w-full max-w-lg bg-cream z-90">
    <div class="flex flex-col h-full">
      <div class="bg-cream px-ogs py-6 border-b border-grey">
        <div class="grid grid-cols-2">
          <div class="col-span-1 text-md lg:text-lg font-semibold leading-none">
            <div class="flex items-center space-x-1">
              <div>Your Cart</div>
              <div>({{ cart.totalQty }})</div>
            </div>
          </div>
          <div class="col-span-1 justify-end flex items-center">
            <button class="link" @click="$store.commit('closeSiteCart')">
              <div class="w-5 h-5">
                <IconCross />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="w-full h-full overflow-y-auto scrolling-touch px-ogs py-6">
        <div class="mb-6 space-y-2">
          <div
            v-if="siteBannerText"
            class="bg-sand-darker uppercase w-full p-2 text-center rounded-md text-xs font-semibold tracking-widest"
          >
            {{ siteBannerText }}
          </div>
          <div
            v-if="cartBannerText"
            class="bg-sand-darker uppercase w-full p-2 text-center rounded-md text-xs font-semibold tracking-widest"
          >
            {{ cartBannerText }}
          </div>
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="isLoading" class="space-y-4">
            <div class="h-32">
              <LoaderSkeleton />
            </div>
            <div class="h-32">
              <LoaderSkeleton />
            </div>
            <div class="h-32">
              <LoaderSkeleton />
            </div>
            <div class="h-32">
              <LoaderSkeleton />
            </div>
            <div class="h-32">
              <LoaderSkeleton />
            </div>
          </div>
          <div v-else key="cart" class="w-full">
            <div
              v-if="error"
              key="error"
              role="alert"
              class="bg-red text-white uppercase w-full py-2 text-center rounded-md text-xs font-semibold tracking-widest mb-6 flex"
              data-testid="error-state"
            >
              <h3 class="flex-1">{{ error }}</h3>
              <button
                class="w-3 h-3 flex-shrink-0 mt-2px mr-3 lg:mr-5 link"
                @click="setError(null)"
              >
                <IconCrossSmall />
              </button>
            </div>
            <SiteCartItems
              :line-items="lineItems"
              @removed-item="removeItem($event)"
            />
          </div>
        </transition>
      </div>
      <div class="w-full px-ogs pb-6 bg-cream text-md leading-snug">
        <div class="grid grid-cols-3 gap-x-4 gap-y-1 border-t py-4">
          <div class="col-span-2">Subtotal</div>
          <div class="col-span-1 flex items-center justify-end">
            <transition name="fade" mode="out-in">
              <div v-if="isLoading">
                <LoaderSpinner
                  role="progressbar"
                  aria-valuetext="Loading"
                  aria-busy="true"
                  class="w-4 h-4 text-black"
                />
              </div>
              <div v-else>
                {{ cart && cart.itemTotalAsCurrency }}
              </div>
            </transition>
          </div>
          <div class="col-span-1">Shipping</div>
          <div class="col-span-2 flex items-center justify-end text-right">
            <transition name="fade" mode="out-in">
              <div v-if="isLoading">
                <LoaderSpinner
                  role="progressbar"
                  aria-valuetext="Loading"
                  aria-busy="true"
                  class="w-4 h-4 text-black"
                />
              </div>
              <div v-else>
                {{
                  hasFreeShipping && !isEmpty
                    ? "Free"
                    : " Calculated at checkout"
                }}
              </div>
            </transition>
          </div>
        </div>
        <div class="grid grid-cols-2 items-start gap-4 border-t py-4">
          <div class="col-span-1 flex flex-col">
            <span class="font-semibold uppercase">Total</span>
            <span
              v-if="!isLoading && cart.totalTaxIncluded !== 0"
              class="text-sm"
              >Including taxes of
              {{ cart && cart.totalTaxIncludedAsCurrency }}</span
            >
          </div>
          <div class="col-span-1 flex items-center justify-end">
            <transition name="fade" mode="out-in">
              <div v-if="isLoading">
                <LoaderSpinner
                  role="progressbar"
                  aria-valuetext="Loading"
                  aria-busy="true"
                  class="w-4 h-4 text-black"
                />
              </div>
              <div v-else>
                {{ cart && cart.itemTotalAsCurrency }}
              </div>
            </transition>
          </div>
        </div>
        <a
          href="/shop/checkout"
          class="py-4 w-full font-bold"
          :class="{
            'btn-strong': canCheckout,
            'btn-soft pointer-events-none opacity-50': !canCheckout,
          }"
        >
          Checkout Now
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import IconCross from "../../../templates/_includes/svg/icon-cross.svg";
import SiteCartItems from "./SiteCartItems.vue";
import LoaderSpinner from "../ui/LoaderSpinner.vue";
import LoaderSkeleton from "../ui/LoaderSkeleton.vue";
import IconCrossSmall from "../../../templates/_includes/svg/icon-cross-small.svg";

export default {
  name: "SiteCart",

  components: {
    IconCross,
    SiteCartItems,
    LoaderSpinner,
    LoaderSkeleton,
    IconCrossSmall,
  },

  props: {
    siteBannerText: {
      type: String,
      default: "",
    },
    cartBannerText: {
      type: String,
      default: "",
    },
  },

  computed: {
    isEmpty() {
      return this.cart.totalQty === 0;
    },

    lineItems() {
      return this.cart ? this.cart.lineItems : [];
    },

    hasFreeShipping() {
      return this.lineItems.every(
        (lineItem) =>
          lineItem.snapshot.productTypeHandle === "paintTestPots" ||
          lineItem.snapshot.productTypeHandle === "coatingTestPots"
      );
    },

    ...mapState("cart", ["cart", "error", "isLoading"]),

    canCheckout() {
      return !this.isEmpty && !this.isLoading;
    },
  },

  methods: {
    ...mapActions("cart", ["removeItem"]),
    ...mapMutations("cart", ["setError"]),
  },
};
</script>

<style scoped lang="css">
.bg-sand-darker {
  background: #d4c7ae;
}
.border-grey {
  border-color: #8a8989;
}
</style>
