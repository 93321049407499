<template>
  <div class="relative bg-smoke rounded-xl overflow-hidden transform-gpu">
    <div
      class="absolute inset-y-0 left-0 z-10 border-r border-charcoal bg-smoke flex items-center"
    >
      <button
        class="px-2.75 md:px-4 h-full hover:opacity-70 focus:outline-none"
        @click="$flickity.previous(true)"
      >
        <svg class="w-2.75 stroke-current" viewBox="0 0 11 22">
          <path
            d="M10.3337 21.0007L0.999999 11.3034L10.3337 0.999988"
            fill="none"
          />
        </svg>
        <span class="sr-only">Previous Slide</span>
      </button>
    </div>
    <div
      class="absolute inset-y-0 right-0 z-10 border-l border-charcoal bg-smoke flex items-center"
    >
      <button
        class="px-2.75 md:px-4 h-full hover:opacity-70 focus:outline-none"
        @click="$flickity.next(true)"
      >
        <svg class="w-2.75 stroke-current" viewBox="0 0 11 22">
          <path d="M0.666318 21.0007L10 11.3034L0.66632 0.999988" fill="none" />
        </svg>
        <span class="sr-only">Next Slide</span>
      </button>
    </div>
    <div
      v-if="colours.length > 0"
      :key="`${selectedFilter.title}-${colours.length}`"
      ref="slider"
      class="slider w-full block overflow-x-auto md:overflow-x-hidden overflow-y-hidden scrolling-touch"
    >
      <div
        v-for="(colour, colourIndex) in colours"
        :key="colour.hex"
        class="slide w-auto"
        :class="`colour-${colour.id}`"
      >
        <div class="slide__inner">
          <div
            class="bg-white py-2px my-1 md:my-1.5 lg:mb-2 lg:mt-3"
            :class="[
              colourIndex === 0 ? 'rounded-l-2xl pl-1' : '',
              colourIndex === colours.length - 1 ? 'rounded-r-2xl pr-1' : '',
            ]"
          >
            <div class="flex items-center h-16 space-x-1 px-2px">
              <ColourSelectorPaintButton
                v-if="isColourPage || isPaintProduct"
                :colour="colour"
                :selected-colour="selectedColour"
                :is-colour-page="isColourPage || isPaintProduct"
                @handle-click="handleClick"
                @change-colour="$emit('change-colour', colour)"
              />
              <ColourSelectorCoatingButton
                v-if="isCoatingProduct"
                :colour="colour"
                :selected-colour="selectedColour"
                @change-colour="$emit('change-colour', colour)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="h-18 md:h-22 flex items-center justify-center w-2/3 mx-auto text-center"
    >
      There are no colours that match your filters
    </div>
  </div>
</template>

<script>
import Flickity from "flickity";
import ColourSelectorPaintButton from "./ColourSelectorPaintButton.vue";
import ColourSelectorCoatingButton from "./ColourSelectorCoatingButton.vue";

export default {
  name: "ColourSelectorSliderNew",

  components: {
    ColourSelectorPaintButton,
    ColourSelectorCoatingButton,
  },

  props: {
    colours: {
      type: Array,
      required: true,
    },

    selectedColour: {
      type: [Object, Boolean],
      required: true,
    },

    isMobile: {
      type: Boolean,
      default: false,
    },

    isColourPage: {
      type: Boolean,
      default: false,
    },

    isPaintProduct: {
      type: Boolean,
      default: false,
    },

    isCoatingProduct: {
      type: Boolean,
      default: false,
    },
    triggerNextSlide: {
      type: Number,
      default: 0,
    },
    triggerPrevSlide: {
      type: Number,
      default: 0,
    },
    scrollTo: {
      type: Number,
      default: 0,
    },
    showOurFaves: {
      type: Boolean,
      default: false,
    },
    selectedFilter: {
      type: Object,
      required: true,
    },
  },

  watch: {
    triggerNextSlide() {
      this.$flickity.next(true);
    },
    triggerPrevSlide() {
      this.$flickity.previous(true);
    },
    scrollTo() {
      this.$flickity.selectCell(`.colour-${this.scrollTo}`);
    },
    showOurFaves() {
      this.$nextTick(() => {
        this.reinitFlickity();
      });
    },
    selectedFilter() {
      this.$nextTick(() => {
        this.reinitFlickity();
      });
    },
  },

  mounted() {
    this.initFlickity();
  },

  destroyed() {
    // destroy after transition finishes 300ms
    setTimeout(() => {
      this.$flickity.destroy();
      this.$flickity = null;
    }, 500);
  },

  methods: {
    handleClick(colour) {
      if (this.isMobile || this.isColourPage) return;
      this.$emit("select-colour", colour);
    },
    initFlickity() {
      let groupCells;

      if (matchMedia("screen and (max-width: 768px)").matches) {
        groupCells = "75%";
      } else {
        groupCells = "90%";
      }

      if (this.colours.length > 0) {
        this.$flickity = new Flickity(this.$refs.slider, {
          freeScroll: true,
          contain: true,
          prevNextButtons: false,
          pageDots: false,
          watchCSS: true,
          groupCells,
        });
      }
    },
    reinitFlickity() {
      if (this.$flickity.element !== undefined) {
        this.$flickity.destroy();
      }
      this.initFlickity();
    },
  },
};
</script>

<style scoped lang="css">
.slider:after {
  content: "flickity";
  display: none;
}

.slide:first-child .slide__inner {
  @apply pl-10 md:pl-15;
}

.slide:last-child .slide__inner {
  @apply pr-10 md:pr-15;
}
</style>
