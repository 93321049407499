<template>
  <div
    :style="{ backgroundColor: backgroundColour }"
    :class="`text-${textColour}`"
  >
    <swiper
      ref="swiper"
      class="swiper relative h-full"
      :options="swiperOptions"
    >
      <div
        slot="pagination"
        class="md:absolute inset-x-0 bottom-0 z-20"
        :class="
          entryType === 'paint' ? 'pb-15 md:pb-ogs-large' : 'pb-ogs-large'
        "
      >
        <div class="grid grid-cols-2">
          <div class="col-span-2 md:col-span-1 flex md:justify-center px-ogs">
            <div
              class="w-full"
              :class="
                entryType === 'paint'
                  ? 'md:max-w-md lg:max-w-lg'
                  : 'md:max-w-sm'
              "
            >
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
      <swiper-slide
        v-for="(item, index) in testimonials"
        :key="index"
        :style="{ height: 'auto !important' }"
      >
        <div class="h-full grid grid-cols-2">
          <div
            class="row-start-2 md:row-start-auto col-span-2 md:col-span-1 px-ogs"
            :class="
              entryType === 'paint' ? 'pt-15 pb-ogs-large' : 'py-ogs-large'
            "
          >
            <div
              class="h-full flex md:items-center md:justify-center md:pb-ogs-large"
            >
              <div
                class="w-full"
                :class="
                  entryType === 'paint'
                    ? 'md:max-w-md lg:max-w-lg'
                    : 'md:max-w-sm'
                "
              >
                <div class="flex space-x-2 mb-4 lg:mb-8">
                  <svg
                    v-for="star in Number(item.rating)"
                    :key="star"
                    class="w-4 h-4 fill-current"
                    :class="entryType === 'paint' ? 'text-black' : 'text-pink'"
                    viewBox="0 0 16 15"
                  >
                    <path
                      d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                    />
                  </svg>
                </div>
                <p
                  class="text-lg lg:text-xl leading-relaxed lg:leading-snug mb-3"
                >
                  {{ item.quote }}
                </p>
                <!-- eslint-disable vue/no-v-html -->
                <div class="text-xs lg:text-base" v-html="item.body"></div>
              </div>
            </div>
          </div>
          <div
            class="row-start-1 md:row-start-auto col-span-2 md:col-span-1 bg-grey"
          >
            <div class="pt-11/12 h-full relative">
              <picture>
                <source
                  type="image/webp"
                  :srcset="`${item.image[0].webpSm} 570w,
                    ${item.image[0].webpLg} 1440w`"
                  sizes="(max-width: 570px) 570px, 1440px"
                />
                <img
                  class="absolute top-0 left-0 w-full h-full object-cover"
                  :srcset="`${item.image[0].defaultSm} 570w,
                    ${item.image[0].defaultLg} 1440w`"
                  sizes="(max-width: 570px) 570px, 1440px"
                  :src="item.image[0].defaultLg"
                  :alt="item.image[0].title"
                  loading="lazy"
                  decoding="async"
                  width="1440"
                  height="1440"
                />
              </picture>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import axios from "axios";

export default {
  name: "FlexibleContentRichTestimonials",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    entry: {
      type: Number,
      default: null,
    },
    block: {
      type: Number,
      default: null,
    },
    entryType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      testimonials: null,
      image: null,
      textColour: null,
      backgroundColour: null,
      swiperOptions: {
        slidesPerView: 1,
        autoplay: {
          delay: 7000,
        },
        speed: 1250,
        loop: true,
        effect: "fade",
        simulateTouch: false,
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      axios({
        url: "/api",
        method: "post",
        data: {
          query: `
            query galleryQuery($entry: [QueryArgument], $block: [QueryArgument]) {
              products(id: $entry) {
                ... on paint_Product {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_testimonials_BlockType {
                      backgroundColour
                      textColour
                      testimonials {
                        ... on testimonials_testimonials_Entry {
                          quote
                          body
                          rating
                          image {
                            webpLg: url(width: 1440, height: 1440, format: "webp")
                            webpSm: url(width: 570, height: 570, format: "webp")
                            defaultLg: url(width: 1440, height: 1440)
                            defaultSm: url(width: 570, height: 570)
                            title
                          }
                        }
                      }
                    }
                  }
                }
                ... on supplementary_Product {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_testimonials_BlockType {
                      backgroundColour
                      textColour
                      testimonials {
                        ... on testimonials_testimonials_Entry {
                          quote
                          body
                          rating
                          image {
                            webpLg: url(width: 1440, height: 1440, format: "webp")
                            webpSm: url(width: 570, height: 570, format: "webp")
                            defaultLg: url(width: 1440, height: 1440)
                            defaultSm: url(width: 570, height: 570)
                            title
                          }
                        }
                      }
                    }
                  }
                }
                ... on coating_Product {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_testimonials_BlockType {
                      backgroundColour
                      textColour
                      testimonials {
                        ... on testimonials_testimonials_Entry {
                          quote
                          body
                          rating
                          image {
                            webpLg: url(width: 1440, height: 1440, format: "webp")
                            webpSm: url(width: 570, height: 570, format: "webp")
                            defaultLg: url(width: 1440, height: 1440)
                            defaultSm: url(width: 570, height: 570)
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
              entries(id: $entry) {
                ... on home_home_Entry {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_testimonials_BlockType {
                      backgroundColour
                      textColour
                      testimonials {
                        ... on testimonials_testimonials_Entry {
                          quote
                          body
                          rating
                          image {
                            webpLg: url(width: 1440, height: 1440, format: "webp")
                            webpSm: url(width: 570, height: 570, format: "webp")
                            defaultLg: url(width: 1440, height: 1440)
                            defaultSm: url(width: 570, height: 570)
                            title
                          }
                        }
                      }
                    }
                  }
                }
                ... on giftVouchers_giftVouchers_Entry {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_testimonials_BlockType {
                      backgroundColour
                      textColour
                      testimonials {
                        ... on testimonials_testimonials_Entry {
                          quote
                          body
                          rating
                          image {
                            webpLg: url(width: 1440, height: 1440, format: "webp")
                            webpSm: url(width: 570, height: 570, format: "webp")
                            defaultLg: url(width: 1440, height: 1440)
                            defaultSm: url(width: 570, height: 570)
                            title
                          }
                        }
                      }
                    }
                  }
                }
                ... on pages_richFlexibleContent_Entry {
                  flexibleContentRich(id: $block) {
                    ... on flexibleContentRich_testimonials_BlockType {
                      backgroundColour
                      textColour
                      testimonials {
                        ... on testimonials_testimonials_Entry {
                          quote
                          body
                          rating
                          image {
                            webpLg: url(width: 1440, height: 1440, format: "webp")
                            webpSm: url(width: 570, height: 570, format: "webp")
                            defaultLg: url(width: 1440, height: 1440)
                            defaultSm: url(width: 570, height: 570)
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            entry: this.entry,
            block: this.block,
          },
        },
      }).then((result) => {
        /* eslint-disable no-unused-vars */
        /* eslint-disable prefer-destructuring */
        let data;
        const { products } = result.data.data;
        const { entries } = result.data.data;
        if (products.length) {
          data = products[0].flexibleContentRich[0];
        }
        if (entries.length) {
          data = entries[0].flexibleContentRich[0];
        }
        this.testimonials = data.testimonials;
        this.textColour = data.textColour;
        this.backgroundColour = data.backgroundColour;
      });
    },
  },
};
</script>

<style scoped>
.swiper >>> .swiper-pagination-bullets {
  @apply relative flex items-center justify-start space-x-2;
}
.swiper >>> .swiper-pagination-bullet {
  @apply block mx-0 w-2 h-2 bg-black opacity-25;
  @apply focus:outline-none;
}
.swiper >>> .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-black opacity-100;
}
</style>
